import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewContainerRef
} from '@angular/core';

import {AddToCartComponent} from '@spartacus/cart/base/components/add-to-cart';
import {ActiveCartFacade, Cart} from '@spartacus/cart/base/root';
import {CmsComponentData, CurrentProductService, LaunchDialogService, ProductListItemContext} from '@spartacus/storefront';
import {CmsAddToCartComponent, EventService, GlobalMessageType, Price, Product, RoutingService} from '@spartacus/core';
import {filter, first, tap} from 'rxjs/operators';
import {Manufacturer, OrderType} from '../../../cart-administration/model/cart-administration.model';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {CartParametersService} from '../../../cart-administration/services/cart-parameters.service';
import {EfaGlobalMessageService} from '@shared/services/efa-global-message.service';
import {ADD_TO_CART} from 'src/app/custom/config/default-add-to-cart-layout.config';
import {AddToCartDialogComponent} from '../add-to-cart-dialog/add-to-cart-dialog.component';
import {CONFIGURATOR_CART_CONFIRMATION} from 'src/app/custom/config/default-configurator-cart-confirmation-layout.config';
import {
  ConfiguratorCartConfirmationMessageComponent
} from '../../../cart-administration/components/configurator-cart-confirmation-message/configurator-cart-confirmation-message.component';
import {
  ConfiguratorCartConfirmationLocalStorageService
} from '../../../cart-administration/services/configurator-cart-confirmation-local-storage.service';
import { ADD_TO_CART_DIALOG_CLOSE_STATUS } from '../../model/add-to-cart-enum.model';


enum ICON_TYPE {
  CART_WHITE = 'CART_WHITE',
}

const CART_ROUTE = 'cart';

@Component({
  selector: 'app-efa-add-to-cart',
  templateUrl: './efa-add-to-cart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfaAddToCartComponent extends AddToCartComponent implements OnInit, OnDestroy {
  @Input() productCode: string;
  @Input() showQuantity = false; // hide quantity
  @Input() product: Product;
  @Input() showQuantityHint = true; // show quantity hint by default
  @Input() useDefaultAddToCart = true;
  @Input() orderInfoRefId = 0;
  @Input() priceAvailable = true;
  @Input() listPricePerUnit: Price;
  @Input() customerPricePerUnit: Price;
  @Input() isCalledFromSERP: boolean;
  @Input() isCalledFromDPD: boolean;
  @Input() productAvailable: boolean = true;

  cart$: Observable<Cart> = this.activeCartService.getActive();
  myIconTypes = ICON_TYPE;
  isCartFromConfigurator = false;

  private readonly customSubscription: Subscription = new Subscription();
  constructor(
    protected currentProductService: CurrentProductService,
    protected cd: ChangeDetectorRef,
    protected activeCartFacade: ActiveCartFacade,
    private readonly cartParametersService: CartParametersService,
    private readonly globalMessageService: EfaGlobalMessageService,
    private readonly launchDialogService: LaunchDialogService,
    private readonly vcr: ViewContainerRef,
    private readonly routingService: RoutingService,
    private readonly configuratorCartConfirmationLocalStorageService: ConfiguratorCartConfirmationLocalStorageService,
    protected eventService: EventService,
    protected component: CmsComponentData<CmsAddToCartComponent>,
    @Optional() protected productListItemContext?: ProductListItemContext

  ) {
    super(currentProductService, cd, activeCartFacade, component, eventService, productListItemContext);

  }

  ngOnInit(): void {
    super.ngOnInit();
    this.customSubscription.add(
      this.activeCartService
      .getActive()
      .pipe(
        filter((cart: Cart) => cart.fromConfigurator)
    ).subscribe(cart => {
      this.isCartFromConfigurator = cart?.fromConfigurator;
      if (this.isCalledFromSERP){
        this.configuratorCartConfirmationLocalStorageService.initSyncSERP(
          cart.code
        );
      }
      if (this.isCalledFromDPD){
        this.configuratorCartConfirmationLocalStorageService.initSyncPDP(
          cart.code
        );
      }
    }));
  }

  addToCart(): void {
    if (this.isCartFromConfigurator){
      if (this.isCalledFromSERP){
        this.handelSERPCall();
      }
      if (this.isCalledFromDPD){
        this.handelPDPCall();
      }
    }
    else{
      if (this.useDefaultAddToCart) {
        this.myOpenModal();
      } else {
        this.cartParametersService.getCurrentOrderType()
        .pipe(
          tap((orderType) => {
          if (this.isInvalidArticleOrderTypeCombination(orderType)) {
            this.globalMessageService.add(
              {key: 'globalMessage.incompatibleArticleAndOrderType'},
              GlobalMessageType.MSG_TYPE_ERROR
            );
          } else {
              this.myOpenModal();
            }
        })).subscribe().unsubscribe();
      }
    }
  }
  private myOpenConfiguratorModal(): void {
    const componentRef$: Observable<ComponentRef<any>> | void =
      this.launchDialogService.launch(CONFIGURATOR_CART_CONFIRMATION, this.vcr);

    if (componentRef$) {
      combineLatest([componentRef$, this.launchDialogService.dialogClose])
        .pipe(
          tap(([componentRef]) => {
            const componentInstance: ConfiguratorCartConfirmationMessageComponent = (
              componentRef as ComponentRef<ConfiguratorCartConfirmationMessageComponent>
            ).instance;
            componentInstance.isCalledFromSERP = this.isCalledFromSERP;
            componentInstance.isCalledFromPDP = this.isCalledFromDPD;
          }),
          filter(([componentRef, dialogClose]) => componentRef != null && dialogClose != null), first()
        )
        .subscribe(
          ([componentRef, dialogClose]: [
            ComponentRef<ConfiguratorCartConfirmationMessageComponent>,
            string
          ]) => {
            if (dialogClose) {
              this.launchDialogService.clear(CONFIGURATOR_CART_CONFIRMATION);

              if (componentRef) {
                componentRef.destroy();
              }

              if (dialogClose === 'success') {
                this.routingService.go({ cxRoute: CART_ROUTE });
              }
            }
          }
        );
    }
  }
  private myOpenModal(): void {
    const componentRef$: Observable<ComponentRef<any>> | void =
      this.launchDialogService.launch(ADD_TO_CART, this.vcr);

    if (componentRef$) {
      combineLatest([componentRef$, this.launchDialogService.dialogClose])
      .pipe(
        tap(([componentRef]) => {
          const componentInstance: AddToCartDialogComponent = (
            componentRef as ComponentRef<AddToCartDialogComponent>
          ).instance;
          componentInstance.useDefault = this.useDefaultAddToCart;
          componentInstance.addToCartForm = this.addToCartForm;
          componentInstance.customerPricePerUnit = this.customerPricePerUnit;
          componentInstance.listPricePerUnit = this.listPricePerUnit;
          componentInstance.orderInfoRefId = this.orderInfoRefId;
          componentInstance.product = this.product;
          componentInstance.productCode = this.productCode;
          componentInstance.productAvailable = this.productAvailable;
        }),
        filter(([componentRef, dialogClose]) => componentRef != null && dialogClose != null), first()
      )
      .subscribe(
        ([componentRef, dialogClose]: [
          ComponentRef<AddToCartDialogComponent>,
          string
        ]) => {
          if (dialogClose) {
            this.launchDialogService.clear(ADD_TO_CART);

            if (componentRef) {
              componentRef.destroy();
              this.addToCartForm.patchValue({
                quantity: 1,
              });
            }

              if (dialogClose === ADD_TO_CART_DIALOG_CLOSE_STATUS.SUCCESS_ROUTE_TO_CONFIGURED_PAGE) {
                this.routingService.go({ cxRoute: CART_ROUTE });
              }
            }
          }
        );
    }
  }

  private isInvalidArticleOrderTypeCombination(orderType: OrderType): boolean {
    return (
      (orderType.manufacturersIncluded &&
        orderType.manufacturers?.length > 0 &&
        orderType.manufacturers?.findIndex(
          (manufacturer: Manufacturer) =>
            manufacturer.id === this.product.manufacturerId
        ) < 0) ||
      (!orderType.manufacturersIncluded &&
        orderType.manufacturers?.length > 0 &&
        orderType.manufacturers?.findIndex(
          (manufacturer: Manufacturer) =>
            manufacturer.id === this.product.manufacturerId
        ) > -1)
    );
  }
  private handelSERPCall(): void{
    this.customSubscription.add(
      this.configuratorCartConfirmationLocalStorageService.getConfiguratorCartConfirmedSERP().subscribe(confirmedSERP => {
        if (!confirmedSERP){
          this.myOpenConfiguratorModal();
        }
        if (confirmedSERP){
          this.routingService.go({ cxRoute: 'cart' });
        }
      })
    );
  }
  private handelPDPCall(): void{
    this.customSubscription.add(
      this.configuratorCartConfirmationLocalStorageService.getConfiguratorCartConfirmedPDP().subscribe(confirmedPDP => {
        if (!confirmedPDP){
          this.myOpenConfiguratorModal();
        }
        if (confirmedPDP){
          this.routingService.go({ cxRoute: 'cart' });
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.customSubscription.unsubscribe();
  }
}
