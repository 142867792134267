import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PDP_CALL_SOURCE } from '@shared/models/shared.model';
import {
  CmsProductCarouselComponent,
  Product,
  CmsProductCarouselComponent as model,
} from '@spartacus/core';
import { ProductCarouselComponent } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

const PRODUCT_CODE_SPACE_MARKER = '$$$';

@Component({
  selector: 'app-efa-product-carousel',
  templateUrl: './efa-product-carousel.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class EfaProductCarouselComponent extends ProductCarouselComponent {
  private readonly data$: Observable<model> = this.componentData.data$.pipe(
    filter((data) => Boolean(data))
  );

  items$: Observable<Observable<Product | undefined>[]> = this.data$.pipe(
    map((data) => {
      const componentMappingExist = !!data.composition?.inner?.length;
      const codes = this.getProductCodes(data);
      return { componentMappingExist, codes };
    }),
    map(({ componentMappingExist, codes }) => {
      const productScope = componentMappingExist
        ? [...this.PRODUCT_SCOPE]
        : [...this.PRODUCT_SCOPE_ITEM];
      return codes.map((code) =>
        this.productService
          .get(code, productScope)
          .pipe(tap((x) => console.log(x)))
      );
    })
  );

  readonly pdpCallSource: PDP_CALL_SOURCE = PDP_CALL_SOURCE.CAROUSEL;

  private getProductCodes(data: CmsProductCarouselComponent): string[] {
    return (data.productCodes?.trim().split(' ') ?? []).map((code: string) =>
      code.replaceAll(PRODUCT_CODE_SPACE_MARKER, ' ')
    );
  }
}
