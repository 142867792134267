import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const AUTHORIZATION_HTTP_HEADER = 'Authorization';

@Injectable({ providedIn: 'root' })
export class SubaruAspAuthHttpHeaderInterceptor implements HttpInterceptor {
  intercept(
    httpRequest: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      httpRequest.url.includes(
        environment.oidc.subaru.baseUrl +
          '/' +
          environment.oidc.subaru.revokeEndpoint
      )
    ) {
      const request = httpRequest.clone({
        headers: httpRequest.headers.set(AUTHORIZATION_HTTP_HEADER, ''),
      });
      return next.handle(request);
    }

    return next.handle(httpRequest);
  }
}
