import { Component } from '@angular/core';
import { PDP_CALL_SOURCE } from '@shared/models/shared.model';
import { CmsService, Page } from '@spartacus/core';
import {
  ProductGridItemComponent,
  ProductListItemContext,
  ProductListItemContextSource,
} from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { ProductListOptionsService } from "../../services/product-list-options.service";

@Component({
  selector: 'app-efa-product-grid-item',
  templateUrl: './efa-product-grid-item.component.html',
  providers: [
    ProductListItemContextSource,
    {
      provide: ProductListItemContext,
      useExisting: ProductListItemContextSource,
    },
  ],
})
export class EfaProductGridItemComponent extends ProductGridItemComponent {
  pdpCallSource: PDP_CALL_SOURCE;

  private readonly subscription: Subscription = new Subscription();

  constructor(
    protected productListItemContextSource: ProductListItemContextSource,
    protected cmsService: CmsService,
    protected optionsService: ProductListOptionsService
  ) {
    super(productListItemContextSource);
  }

  ngOnInit(): void {

    this.subscription.add(
      this.cmsService.getCurrentPage().subscribe((page: Page) => {
        if (page?.pageId === 'search') {
          this.pdpCallSource = PDP_CALL_SOURCE.SEARCH;
        } else {
          this.pdpCallSource = PDP_CALL_SOURCE.CATEGORY;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
