import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PdfDocumentComponent} from "./components/pdf-document/pdf-document.component";
import {ConfigModule} from "@spartacus/core";

@NgModule({
  declarations: [PdfDocumentComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        PDFDocumentComponent: {
          component: PdfDocumentComponent,
        },
      },
      routing: {
        routes: {
          returnGuideline: {
            paths: ['return-guideline'],
            protected: false,
          }
        }
      },
    }),
  ]
})
export class PdfDocumentModule {
}
