export const loginRegister = {
  efaLoginForm: {
    title: 'Sign in to your account',
    titleNew: 'I\'m new here.',
    textReturningCustomer: 'Are you already a customer, and are you logging into the new webshop for the first time today?',
    partnerId: {
      label: 'Your customer number',
      placeholder: 'Your customer number'
    },
    forgotPassword: 'Forgot password',
    activateAccount: 'Start account activation for the webshop',
    activateAccountDescription: 'Please activate your existing account first.',
    registrationStart: 'Create your customer account in just a few steps.',
    signIn: 'Sign In',
    register: 'Register as new customer',
    dontHaveAccount: 'Don’t have an account?',
    guestCheckout: 'Guest Checkout',
    emailAddress: {
      label: 'Email address of your customer account',
      placeholder: 'Your email address',
    },
    password: {
      label: 'Password',
      placeholder: 'Password',
    },
    wrongEmailFormat: 'This is not a valid email format.',
    separator: 'Or',
  },
  efaHttpHandlers: {
    tokenWasRefreshed: 'The link to confirm the registration has expired. We have sent a new link to you email address. Please try again.',
    registrationAlreadyConfirmed: 'The registration was already confirmed and the data was forwarded to create the user account.',
    tokenNotFound: 'The passed token is invalid or could not be found. Please contact the support.',
    loginDeactivated: 'Currently it is not possible to login with your login data. Please contact our customer service:',
    b2bUnitInactive: 'We will be happy to check with you the reasons for the blocking of your account, and will promptly help you reactivate it. Therefore, please send us an email to:',
    placeOrderTimeoutError: 'Your order was submitted to our system successfully and was added to the waiting queue. Please check in some minutes, if there is a new order listed in order overview of My Account area.',
    placeOrderDuplicateError: 'There is already an order in the waiting queue created from the current cart with the inserted customer reference. Please clean up your cart and change the customer reference to create a new order.',
    calculationError: 'Error when calculating cart. Please check quantities and prices of your current cart.',
    subaruAspAuthenticationError: 'Authentication to EFA web shop using single-sign-on has failed. Please ensure that the customer number from portal is assigned to a customer of the web shop.'
  },
  efaConsentDialog: {
    labelDecline: 'Decline',
    labelAccept: 'Accept',
    termsAndConditions: 'Terms and Conditions',
    noTermsConditions_line1: 'You must give your consent to the terms and conditions of the shop. Otherwise you must not use the web shop and will be logout immediately.',
    noTermsConditions_line2: 'You can check the details by clicking the following link: ',
  },
  efaRegistrationForm: {
    formMandatoryFields: 'Mandatory fields are marked with *.',
    legend1: 'Company and contact details',
    legend2: 'Type of company',
    legend3: 'Specialization',
    legend4: 'Affiliation',
    legend5: 'Contact persons',
    legend6: 'Bank Details',
    legend7: 'Email Addresses',
    legend8: 'Delivery',
    legend9: 'Documents',
    legend10: 'Would you like to always receive the latest information and offers in the future? Then register here for our newsletter.',
    legend11: 'Finally, tell us briefly how you found out about us?',
    legend12: 'Submit registration now',
    title: 'Salutation',
    selectPosition: 'Select position',
    selectTitle: 'Select title',
    companyName: {
      label: 'Company',
      placeholder: 'Company',
    },
    firstName: {
      label: 'First name',
      placeholder: 'First name',
    },
    lastName: {
      label: 'Last name',
      placeholder: 'Last name',
    },
    yourFirstName: {
      label: 'First name',
      placeholder: 'Your first name',
    },
    yourLastName: {
      label: 'Last name',
      placeholder: 'Your last name',
    },
    phone: {
      label: 'Phone',
      placeholder: 'Phone',
    },
    street: {
      label: 'Street',
      placeholder: 'Street',
    },
    streetNumber: {
      label: 'House number',
      placeholder: 'House number',
    },
    postalCode: {
      label: 'Postal code',
      placeholder: 'Postal code',
    },
    town: {
      label: 'Town',
      placeholder: 'Town',
    },
    countryIsoCode: {
      label: 'Country',
      selectCountry: 'Select country',
    },
    taxId: {
      label: 'Sales tax ID or tax number',
      placeholder: 'VAT ID eg DE999999999 or tax number',
    },
    emailAddress: {
      label: 'Email address',
      placeholder: 'Email address',
    },
    sepaBank: {
      label: 'Bank',
      placeholder: 'Credit institution',
    },
    sepaIban: {
      label: 'IBAN',
      placeholder: 'IBAN',
    },
    sepaBic: {
      label: 'BIC',
      placeholder: 'BIC',
    },
    sepaEmailAddress: {
      label: 'Email for payment notification',
      placeholder: 'Email for payment notification',
    },
    contactPersonEmailAddress: {
      label: 'Email address of contact person',
      placeholder: 'Email address of contact person',
    },
    orderConfirmationEmailAddress: {
      label: 'Order confirmation email address',
      placeholder: 'Order confirmation email address',
    },
    deliveryNoteForwardingEmailAddress: {
      label: 'Delivery note forwarding email address',
      placeholder: 'Delivery note forwarding email address',
    },
    invoiceForwardingEmailAddress: {
      label: 'Invoice forwarding email address',
      placeholder: 'Invoice forwarding email address',
    },
    newsletterEmailAddress: {
      label: 'Email for newsletter',
      placeholder: 'Email for newsletter',
    },
    membership: {
      label: 'Association',
      placeholder: 'e.g. HUK, Innovation',
    },
    membershipId: {
      label: 'Association ID number',
      placeholder: 'Association ID number',
    },
    deliveryCosts: {
      label: 'Shipping',
      confirmation: 'We charge a shipping fee of €9.60 per order (plus statutory VAT).',
    },
    deliveryPerNoxNightExpress: {
      label: 'Goods are delivered by NOX night express',
      confirmation: 'We understand that if goods are delivered to a non-lockable depot EFA Autoteilewelt GmbH excludes liability for goods resulting from loss, damage, financial loss or other consequences (e.g. a possible loss due to theft).',
    },
    noxDepotAvailable: {
      label: 'Lockable NOX depot available',
      option1: 'Yes',
      option2: 'No'
    },
    noxCustomerNumber: {
      label: 'If so, NOX customer number',
      placeholder: 'NOX customer number'
    },
    contactPerson: '. Point of contact',
    addContactPerson: 'Add another contact person',
    removeContactPerson: 'Remove contact person',
    contactPersonPosition: 'Position',
    selectContactPersonPosition: 'Select position',
    role: 'Position',
    newsletter: 'I would like to register for the newsletter.',
    confirmThatRead1: 'I confirm that I have read the',
    confirmThatRead2: 'and accept them.',
    termsAndConditions: 'General Terms and Conditions',
    confirmPrivacyProtection1: 'I confirm that I have read the',
    confirmPrivacyProtection2: 'and accept them.',
    privacyProtection: 'Privacy Policy',
    globalMessage: {
      invalidIban: 'An error occured during the IBAN check. Please check your IBAN and try again.',
      registrationFailure: 'An error occurred during registration.',
      registrationSuccess: 'The registration was processed successfully. We have sent you an email to confirm your registration. Please confirm your email address within 30 minutes.',
      registrationConfirmFailure: 'An error occurred during confirmation of the registration. Please try again.',
    },
    registerButton: 'Submit',
    gender: {
      MALE: 'Mr.',
      FEMALE: 'Mrs./Ms.',
      OTHER: 'Other'
    },
    formErrors: {
      companyName: {
        required: 'Please enter your full company name',
      },
      role: {
        required: 'Please indicate that you are the owner or managing director of the company (authorized officer = managing director)',
      },
      title: {
        required: 'Your salutation, “Mr.”, “Ms./Mrs.”, ”Other”',
      },
      yourFirstName: {
        required: 'Your first name',
      },
      yourLastName: {
        required: 'Your last name',
      },
      taxId: {
        required: 'Please enter your sales tax ID or tax number',
      },
      emailAddress: {
        required: 'The email address of the owner or manager',
        cxInvalidEmail: 'This is not a valid email format',
      },
      street: {
        required: 'Your street',
      },
      streetNumber: {
        required: 'Your house number',
      },
      postalCode: {
        required: 'Your postal code',
      },
      town: {
        required: 'Your town',
      },
      countryIsoCode: {
        required: 'In which country is your company located?',
      },
      businessTypeCode: {
        required: 'In order to be able to optimally adapt our services to you, please indicate your operating mode',
      },
      firstName: {
        required: 'Your first name',
      },
      lastName: {
        required: 'Your last name',
      },
      phone: {
        required:
          'Please enter your telephone number so that we can reach you optimally.',
      },
      contactPersonEmailAddress: {
        required: 'Please enter the email of your main contact person here.',
        cxInvalidEmail: 'This is not a valid email format',
      },
      sepaBank: {
        required: 'Please enter the name of your bank.',
      },
      sepaIban: {
        required: 'Please enter your IBAN.',
      },
      sepaBic: {
        required: 'Please enter your BIC.',
      },
      sepaEmailAddress: {
        required: 'We will send payment notifications to this email.',
        cxInvalidEmail: 'This is not a valid email format',
      },
      deliveryCosts: {
        required: 'Please confirm the shipping fee.',
      },
      deliveryPerNoxNightExpress: {
        required: 'To be on the safe side, please confirm the conditions for the delivery of goods by NOX Overnight Express.',
      },
      noxDepotAvailable: {
        required: 'Please indicate whether a NOX depot is available.',
      },
      uploadPdfFile: {
        invoiceSheet: {
          required: 'Please upload your company/invoice sheet as a PDF file.',
          validFileExtension: 'Please upload your company/invoice sheet as a PDF file.',
          maxFileSizeExceeded: 'The maximum file size ({{maxFileSize}} MB) was exceeded.',
        },
        businessRegistration: {
          required: 'Please upload your commercial application as a PDF file.',
          validFileExtension: 'Please upload your commercial application as a PDF file.',
          maxFileSizeExceeded: 'The maximum file size ({{maxFileSize}} MB) was exceeded.',
        },
        craftRegistration: {
          validFileExtension: 'Please upload your entry in the craft register or craft card as a PDF file.',
          maxFileSizeExceeded: 'The maximum file size ({{maxFileSize}} MB) was exceeded.',
        },
        mercedesDeclaration: {
          validFileExtension: 'Please upload your Mercedes statement as a PDF file.',
          maxFileSizeExceeded: 'The maximum file size ({{maxFileSize}} MB) was exceeded.',
        },
        volkswagenDeclaration: {
          validFileExtension: 'Please upload your VW statement as a PDF file.',
          maxFileSizeExceeded: 'The maximum file size ({{maxFileSize}} MB) was exceeded.',
        },
        bmwDeclaration: {
          validFileExtension: 'Please upload your BMW statement as a PDF file.',
          maxFileSizeExceeded: 'The maximum file size ({{maxFileSize}} MB) was exceeded.',
        },
        noxDeliveryInfo: {
          validFileExtension: 'Please upload your storage information NOX as a PDF file.',
          maxFileSizeExceeded: 'The maximum file size ({{maxFileSize}} MB) was exceeded.',
        },
      },
      termsAndConditions: {
        required: 'Please confirm our terms and conditions to send your registration.',
      },
      privacyProtection: {
        required: 'Please confirm the privacy policy to submit your registration.',
      },
    },
  },
  efaRegistrationConfirmation: {
    error: 'Your registration could not be finished successfully.'
  },
  efaRegistrationStart: {
    headline: 'Registration',
    continueToRegistration: 'Continue to Registration'
  },
  efaForgotPasswordForm: {
    submitActivateAccount: 'Start account activation',
    cancelActivateAccount: 'Back',
    submitForgotPassword: 'Receive reset email',
    cancelForgotPassword: 'Back to login',
  },
  ssoLogin: {
    globalMessage: {
      ssoLoginFailure: 'SSO Login Failed'
    }
  }
};
