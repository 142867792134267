import { Component } from '@angular/core';

import { EfaGlobalMessageService } from '@shared/services/efa-global-message.service';
import {
  AnonymousConsentsConfig,
  AnonymousConsentsService,
  AuthService,
  B2BUser,
  ConsentTemplate,
  EventService,
  RoutingService,
  UserConsentService,
} from '@spartacus/core';
import {
  ConsentManagementComponent,
  ConsentManagementComponentService,
  LAUNCH_CALLER,
  LaunchDialogService,
} from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { filter, Subscription } from 'rxjs';
import { TermsConfirmationEvent } from '../../../login-register/model/login-register.model';

declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    CONSENT_MANAGEMENT = 'CONSENT_MANAGEMENT',
  }
}

@Component({
  selector: 'app-efa-consent-management',
  templateUrl: './efa-consent-management.component.html',
})
export class EfaConsentManagementComponent extends ConsentManagementComponent {
  private currentUser: B2BUser;
  private subscription: Subscription = new Subscription();

  constructor(
    protected userConsentService: UserConsentService,
    protected globalMessageService: EfaGlobalMessageService,
    protected anonymousConsentsConfig: AnonymousConsentsConfig,
    protected anonymousConsentsService: AnonymousConsentsService,
    protected authService: AuthService,
    protected consentManagementComponentService: ConsentManagementComponentService,
    private launchDialogService: LaunchDialogService,
    private routingService: RoutingService,
    private eventService: EventService,
    private userAccountFacade: UserAccountFacade
  ) {
    super(
      userConsentService,
      globalMessageService,
      anonymousConsentsConfig,
      anonymousConsentsService,
      authService,
      consentManagementComponentService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(
      this.userAccountFacade
        .get()
        .pipe(filter(Boolean))
        .subscribe((u: B2BUser) => (this.currentUser = u))
    );
  }
  onConsentChange({
    given,
    template,
  }: {
    given: boolean;
    template: ConsentTemplate;
  }): void {
    if (given && template.id === '002-TERMS_CONDITIONS') {
      this.userConsentService.giveConsent(template.id, template.version);
    } else if (template.id === '002-TERMS_CONDITIONS') {
      const dialog = this.launchDialogService.openDialogAndSubscribe(
        LAUNCH_CALLER.CONSENT_MANAGEMENT,
        undefined,
        undefined
      );
      this.launchDialogService.dialogClose.subscribe((result) =>
        this.triggerTermsAndConditions(template, result)
      );
    } else {
      super.onConsentChange({ given, template });
    }
  }

  triggerTermsAndConditions(
    consentTemplate: ConsentTemplate,
    result: boolean
  ): void {
    if (result === undefined) {
      return;
    }

    if (result) {
      this.userConsentService.withdrawConsent(
        consentTemplate.currentConsent.code
      );

      if (this.currentUser.portalUser) {
        this.eventService.dispatch(new TermsConfirmationEvent(false));
        this.authService
          .coreLogout()
          .then(() => (window.location.href = window.location.origin));
      } else {
        this.routingService.go({ cxRoute: 'logout' });
        this.eventService.dispatch(new TermsConfirmationEvent(false));
      }
    } else {
      window.location.reload();
    }
  }
}
