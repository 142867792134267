import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AuthService,
  EventService,
  RoutingService,
  UserConsentService,
} from '@spartacus/core';
import { map, Subscription } from 'rxjs';
import { TermsConfirmationEvent } from '../../model/login-register.model';

@Component({
  selector: 'app-terms-and-conditions-confirmation-action',
  templateUrl: './terms-and-conditions-confirmation-action.component.html',
})
export class TermsAndConditionsConfirmationActionComponent
  implements OnInit, OnDestroy
{
  private forceCoreLogoutAndReload: boolean = false;
  private subscription: Subscription = new Subscription();

  constructor(
    private routingService: RoutingService,
    private userConsentService: UserConsentService,
    private authService: AuthService,
    private eventService: EventService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.routingService
        .getRouterState()
        .pipe(
          map(
            (routingData) =>
              routingData.state.queryParams.forceCoreLogoutAndReload
          )
        )
        .subscribe(
          (forceCoreLogoutAndReload: boolean) =>
            (this.forceCoreLogoutAndReload = forceCoreLogoutAndReload)
        )
    );
  }

  declineTermsAndConditions(): void {
    if (this.forceCoreLogoutAndReload) {
      this.eventService.dispatch(new TermsConfirmationEvent(false));
      this.authService
        .coreLogout()
        .then(() => (window.location.href = window.location.origin));
    } else {
      this.authService.logout();
      this.routingService.go({cxRoute: 'logout'});  
      this.eventService.dispatch(new TermsConfirmationEvent(false));
    }
  }

  acceptTermsAndConditions(): void {
    this.userConsentService.giveConsent('002-TERMS_CONDITIONS', 0);
    this.routingService.go({ cxRoute: 'home' });
    this.eventService.dispatch(new TermsConfirmationEvent(true));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
