import { AuthConfig } from '@spartacus/core';
import { environment } from 'src/environments/environment';

const defaultAuthConfig: AuthConfig = {
  authentication: {
    client_id: 'mobile_android',
    client_secret: 'secret',
    tokenEndpoint: '/oauth/token',
    revokeEndpoint: '/oauth/revoke',
    loginUrl: '/oauth/authorize',
    OAuthLibConfig: {
      scope: '',
      customTokenParameters: ['token_type'],
      strictDiscoveryDocumentValidation: false,
      skipIssuerCheck: true,
      disablePKCE: true,
      oidc: false,
      clearHashAfterLogin: false,
    },
  },
};

const oidcEnvironment = environment.oidc;
const siteIdToUrlSubDirectoryEnvironment = environment.siteIdToUrlSubDirectory;
type OIDCEnvironmentProperty = keyof typeof oidcEnvironment;

export function getAuthConfig(): AuthConfig {
  const prop: OIDCEnvironmentProperty | undefined =
    getOIDCEnvironmentProperty();

  if (prop === undefined) {
    return defaultAuthConfig;
  }

  const shopOrigin: string = window.location.origin;
  const shopUri: string = shopOrigin + window.location.pathname;

  const config: AuthConfig = {
    authentication: {
      client_id: oidcEnvironment[prop].clientId,
      client_secret: oidcEnvironment[prop].clientSecret,
      loginUrl: oidcEnvironment[prop].loginUrl,
      tokenEndpoint: oidcEnvironment[prop].tokenEndpoint,
      userinfoEndpoint: oidcEnvironment[prop].userinfoEndpoint,
      revokeEndpoint: oidcEnvironment[prop].revokeEndpoint,
      baseUrl: oidcEnvironment[prop].baseUrl,
      OAuthLibConfig: {
        responseType: 'code',
        scope: 'openid',
        postLogoutRedirectUri: shopOrigin,
        redirectUri: shopUri,
        issuer: oidcEnvironment[prop].issuer,
        disablePKCE: false,
        strictDiscoveryDocumentValidation: true,
        skipIssuerCheck: false,
      },
    },
  };
  return config;
}

function getOIDCEnvironmentProperty(): OIDCEnvironmentProperty {
  const url: string = window.location.href;

  if (url.includes(siteIdToUrlSubDirectoryEnvironment.subaru)) {
    return 'subaru';
  } else {
    return undefined;
  }
}
