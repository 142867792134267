import {Injectable, Type} from '@angular/core';
import {CartEventBuilder} from '@spartacus/cart/base/core';
import {CartAddEntrySuccessEvent, CartModification,} from '@spartacus/cart/base/root';
import {ActionToEventMapping, createFrom} from '@spartacus/core';
import {filter, from, map, max, of, switchMap, withLatestFrom} from 'rxjs';
import {MultiAddToCartActions} from '../store/actions';

const USER_ID = 'userId';
const CART_ID = 'cartId';
const MODIFICATIONS = 'modifications';

@Injectable({providedIn: 'root'})
export class EfaCartEventBuilder extends CartEventBuilder {
  protected register() {
    super.register();
    this.registerMultiAddToCart();
  }

  protected registerMultiAddToCart(): void {
    this.registerFlatMapped({
      action: MultiAddToCartActions.MULTI_ADD_TO_CART_SUCCESS,
      event: CartAddEntrySuccessEvent,
    });
  }

  protected registerFlatMapped<T>(
    mapping: ActionToEventMapping<T>
  ): () => void {
    const eventStream$ = this.getAction(mapping.action).pipe(
      switchMap((action) => {
        // SwitchMap was used instead of withLatestFrom, because we only want to subscribe to cart stream when action is dispatched.
        // Using withLatestFrom would trigger subscription to cart observables on event subscription and that causes side effects,
        // such as loading cart when we don't yet need it.
        return of(action).pipe(
          withLatestFrom(
            this.activeCartService.getActive(),
            this.activeCartService.getActiveCartId(),
          )
        );
      }),
      filter(
        ([action, _activeCart, activeCartId]) =>
          action[CART_ID] === activeCartId
      ),
      map(([action, activeCart]) => {
        const events = [];
        action[MODIFICATIONS]?.cartModifications?.forEach(
          (mod: CartModification) => {
            events.push(
              createFrom(mapping.event, {
                ...(mod as any),
                cartCode: activeCart.code,
                userId: action[USER_ID],
                cartId: action[CART_ID],
                entry: mod.entry
                  ? mod.entry
                  : activeCart.entries?.[Number(mod.entry.entryNumber)],
              })
            );
          }
        );
        return events
      }), switchMap((events) => from(events))
    );
    return this.event.register(mapping.event, eventStream$);
  }

  protected override registerMapped<T>(mapping: ActionToEventMapping<T>): () => void {
    const eventStream$ = this.getAction(mapping.action).pipe(
      switchMap((action) => {
        // SwitchMap was used instead of withLatestFrom, because we only want to subscribe to cart stream when action is dispatched.
        // Using withLatestFrom would trigger subscription to cart observables on event subscription and that causes side effects,
        // such as loading cart when we don't yet need it.
        return of(action).pipe(
          withLatestFrom(
            this.activeCartService.getActive(),
            this.activeCartService.getActiveCartId()
          )
        );
      }),
      filter(
        ([action, _activeCart, activeCartId]) =>
          action.payload['cartId'] === activeCartId
      ),
      map(([action, activeCart]) =>
        createFrom(mapping.event as Type<T>, {
          ...action.payload,
          cartCode: activeCart.code,
          entry: action.payload.entry
            ? action.payload.entry
            : activeCart.entries?.[Math.max(activeCart.entries.length - Number(action.payload.entryNumber) - 1, 0)],
        })
      )
    );
    return this.event.register(mapping.event as Type<T>, eventStream$);
  }
}
