import {Component, OnDestroy, OnInit} from '@angular/core';
import {CmsComponentData} from "@spartacus/storefront";
import {PDFDocumentComponentData} from "../../model/pdf-document.model";
import {Subscription} from "rxjs";
import {OccConfig, RoutingService} from "@spartacus/core";

@Component({
  selector: 'app-pdf-document',
  template: ''
})
export class PdfDocumentComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();

  constructor(private component: CmsComponentData<PDFDocumentComponentData>, private router: RoutingService, private config: OccConfig) {
  }

  ngOnInit(): void {
    this.subscription.add(
      this.component.data$
      .subscribe(data =>
        window.location.href = this.config?.backend?.occ?.baseUrl.concat(data.pdfFile.url))
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
