import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';
import { delay, distinctUntilChanged, withLatestFrom } from 'rxjs/operators';

import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { getInitialDateFrom } from '@shared/utils/date-tools';
import { Config } from '@spartacus/core';
import { ICON_TYPE } from '../../model/return-history-icon.model';
import { ReturnOverviewFilter, ReturnState } from '../../model/return-history.model';
import { ReturnHistoryUiService } from '../../services/return-history-ui.service';

@Component({
  selector: 'app-return-overview-filter',
  templateUrl: './return-overview-filter.component.html',
})
export class ReturnOverviewFilterComponent implements OnInit, OnDestroy {
  @Input() availableReturnStates: ReturnState[];
  @Output() returnOverviewFilterChanges: EventEmitter<ReturnOverviewFilter> = new EventEmitter<ReturnOverviewFilter>();
  returnOverviewFilterForm: FormGroup;
  iconTypes = ICON_TYPE;
  currentDate: Date = new Date();
  maxDate: NgbDateStruct;

  private subscription: Subscription = new Subscription();
  private readonly formProperties = {
    returnNumber: new FormControl('', {updateOn: 'blur'}),
    deliveryNoteNumber: new FormControl('', {updateOn: 'blur'}),
    statusCode: new FormControl(''),
    dateFrom: new FormControl(getInitialDateFrom(this.config.dateFrom.returnOverview)),
    dateTo: new FormControl(undefined),
  };
  private readonly resetFormState = {
    returnNumber: '',
    deliveryNoteNumber: '',
    statusCode: '',
    dateFrom: getInitialDateFrom(this.config.dateFrom.returnOverview),
    dateTo: undefined,
  };
  private wasReset = false;
  private filterHasChanged = false;

  constructor(
    private fb: FormBuilder,
    private returnHistoryUiService: ReturnHistoryUiService,
    @Inject(Config) private config: any,
  ) {
  }

  ngOnInit(): void {
    this.disableFutureDates();
    this.returnOverviewFilterForm = this.fb.group(this.formProperties);
    this.onChanges();
    this.setSelectedFilterEntity();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onChanges(): void {
    this.subscription.add(
      this.returnOverviewFilterForm.valueChanges
      .pipe(
        delay(1000),
        distinctUntilChanged((a, b) =>
          a.returnNumber === b.returnNumber &&
          a.deliveryNoteNumber === b.deliveryNoteNumber &&
          a.statusCode === b.statusCode &&
          a.dateTo === b.dateTo &&
          a.dateFrom === b.dateFrom)
      ).subscribe((filter: ReturnOverviewFilter) => {
        this.returnOverviewFilterChanges.emit(filter);
        this.filterHasChanged = true;
        this.returnHistoryUiService.setSelectedFilterEntity(filter);
      })
    );
  }

  setSelectedFilterEntity(): void {
    this.subscription.add(
      this.returnHistoryUiService.selectedFilterEntity()
      .pipe(
        withLatestFrom(this.returnHistoryUiService.returningFromDetails()))
      .subscribe(([filterEntity, fromDetails]: [ReturnOverviewFilter, boolean]) => {
        let filterToEmit = filterEntity;
        if (this.filterHasChanged) {
          this.filterHasChanged = false;
        } else {
          if (fromDetails) {
          this.returnOverviewFilterForm.patchValue(
            {
              returnNumber: filterEntity.returnNumber,
              deliveryNoteNumber: filterEntity.deliveryNoteNumber,
              statusCode: filterEntity.statusCode,
              dateFrom: filterEntity.dateFrom,
              dateTo: filterEntity.dateTo,
            },
            {emitEvent: false}
          );
        } else if (!this.wasReset) {
          this.wasReset = false;
        } else {
          filterToEmit = this.resetFormState;
        }
      }
        this.returnOverviewFilterChanges.emit(filterToEmit);
      })
    );
  }

  disableFutureDates(): void {
    this.maxDate = {
      day: this.currentDate.getDate(),
      month: this.currentDate.getMonth() + 1,
      year: this.currentDate.getFullYear()
    };
  }

  triggerOnChanges(field: string, event: any): void {
    const patchValue = {};
    patchValue[field] = event.target.value;
    this.returnOverviewFilterForm.patchValue(patchValue);
  }

  clearForm(): void {
    this.wasReset = true;
    this.returnOverviewFilterForm.reset(this.resetFormState);
  }

  getSelectPlaceholderTextClass(value: string): string {
    return value === '' ? 'placeholder-text' : '';
  }
}
