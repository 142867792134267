<cx-carousel [ngClass]="(items$ | async).length > 4 ? 'transform-more' :'transform-less'" [items]="items$ | async"
  [title]="title$ | async" [template]="carouselItem" itemWidth="285px">
</cx-carousel>

<ng-template #carouselItem let-item="item">
  <div class="carousel-item-container">
    <app-product-badge *ngIf="item.productCampaign" [content]="item.productCampaign?.text"
      [style.backgroundColor]="item.productCampaign?.backgroundColor" [style.color]="item.productCampaign?.textColor"
      class="product-carousel-product-badge"></app-product-badge>
    <app-efa-cart-item-add-to-wish-list [product]="item" [orderInfoRefId]="0" [IsAddedFromSearchPage]="true">
    </app-efa-cart-item-add-to-wish-list>

    <a tabindex="0" [routerLink]="{ cxRoute: 'product', params: item } | cxUrl"
      [queryParams]="{ source: pdpCallSource }">
      <cx-media class="cx-product-image" [container]="item.images?.PRIMARY" format="product"></cx-media>
      <div class="pl-2">
        <h3 class="product-name">
          {{ item.name }}
        </h3>
        <p class="manufacturerAID">
          {{ item.manufacturerAID ? item.manufacturerAID : item.code.substring(3,
          item.code.length) }}
        </p>
      </div>
    </a>
    <div class="price">
      <span *ngIf="item.price?.value > 0">
        {{ item.price.formattedValue }}
      </span>
    </div>
    <div class="carousel-item-container-bottom-content">
      <app-efa-add-to-cart [isCalledFromSERP]="true" [product]="item" [showQuantityHint]="false"
        [listPricePerUnit]="item.price" [customerPricePerUnit]="item.price" [priceAvailable]="item.price?.value > 0">
      </app-efa-add-to-cart>
    </div>
  </div>
</ng-template>