<span class="item-container">
   <app-product-badge *ngIf="product.productCampaign" [content]="product.productCampaign?.text"
      [style.backgroundColor]="product.productCampaign?.backgroundColor"
      [style.color]="product.productCampaign?.textColor" class="grid-item-product-badge"></app-product-badge>
   <a [routerLink]="{ cxRoute: 'product', params: product } | cxUrl" [queryParams]="{ source: pdpCallSource }"
      class="cx-product-image-container" tabindex="-1">
      <cx-media class="cx-product-image" [container]="product.images?.PRIMARY" format="product"
         [alt]="product.manufacturerAID"></cx-media>
   </a>
   <a [routerLink]="{ cxRoute: 'product', params: product } | cxUrl" [queryParams]="{ source: pdpCallSource }"
      class="cx-product-name" [innerHTML]="product.nameHtml"></a>
   <a [routerLink]="{ cxRoute: 'product', params: product } | cxUrl" [queryParams]="{ source: pdpCallSource }"
      class="text-left manufacturerAID" [innerHTML]="product.manufacturerAID"></a>
   <div class="cx-product-price-container">

      <div class="cx-product-price" aria-label="Product price"
         *ngIf="product.price?.value > 0; else showNoPriceToDisplay">
         {{ product.price?.formattedValue }}
      </div>

      <ng-template #showNoPriceToDisplay>
         <div class="product-no-price text-left" aria-label="No price">
            {{ 'noPriceToDisplayForProduct' | cxTranslate }}
         </div>
      </ng-template>
   </div>
   <ng-template [cxOutlet]="ProductListOutlets.ITEM_ACTIONS">
      <div class="action-box d-flex">
         <app-efa-add-to-cart [isCalledFromSERP]="true" [product]="product" [showQuantityHint]="false"
            [listPricePerUnit]="product.price" [customerPricePerUnit]="product.price"
            [priceAvailable]="product.price?.value > 0">
         </app-efa-add-to-cart>

         <app-efa-cart-item-add-to-wish-list [product]="product" [orderInfoRefId]="0" [IsAddedFromSearchPage]="true">
         </app-efa-cart-item-add-to-wish-list>
      </div>
   </ng-template>
</span>