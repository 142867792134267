import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function validFileExtension(fileExtensions: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value != null && (control.value as string).length > 0) {
        const filename: string = control.value as string;
        const fileExtensionIndex: number = filename.lastIndexOf('.');
        if (fileExtensionIndex === -1) {
            return {fileExtension: {fileExtension: '', fileExtensions}};
        }
        const fileExtension: string = filename.substring(fileExtensionIndex);
        const foundFileExtension: string = fileExtensions.find((validFileExtension: string) => fileExtension.toLowerCase() === validFileExtension.toLowerCase());
        return foundFileExtension == null ? {validFileExtension: {fileExtension, fileExtensions: fileExtensions.join(', ')}} : null;
      }
      return null;
    };
  }
