import { createAction, props } from '@ngrx/store';

import { ErrorType } from '@shared/models/ErrorTypes';
import {
  AddToCartParam,
  CartModificationList,
} from '../../model/cart-administration.model';

export const MULTI_ADD_TO_CART_RESET =
  '[MultiAddToCart] Load MultiAddToCart Reset';
export const MULTI_ADD_TO_CART_SUCCESS =
  '[MultiAddToCart] Load MultiAddToCart Success';
export const MULTI_ADD_TO_CART_FAIL =
  '[MultiAddToCart] Load MultiAddToCart Failure';

export const loadMultiAddToCart = createAction(
  '[MultiAddToCart] Load MultiAddToCart',
  props<{
    entries: AddToCartParam[];
    userId: string;
    cartId: string;
  }>()
);

export const loadMultiAddToCartReset = createAction(MULTI_ADD_TO_CART_RESET);

export const loadMultiAddToCartSuccess = createAction(
  MULTI_ADD_TO_CART_SUCCESS,
  props<{
    modifications: CartModificationList;
    cartId: string;
    userId: string;
  }>()
);

export const loadMultiAddToCartFailure = createAction(
  MULTI_ADD_TO_CART_FAIL,
  props<{ errorResponse: ErrorType }>()
);
