import { Component } from '@angular/core';
import { PDP_CALL_SOURCE } from '@shared/models/shared.model';
import { Product, ProductReference } from '@spartacus/core';
import { ProductReferencesComponent } from '@spartacus/storefront';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { PriceResponseItem } from '../../model/product-prices.model';

@Component({
  selector: 'app-efa-product-references',
  templateUrl: './efa-product-references.component.html',
})
export class EfaProductReferencesComponent extends ProductReferencesComponent {
  items$: Observable<Observable<Product | undefined>[]> =
    this.productCode$.pipe(
      withLatestFrom(this.componentData$),
      tap(([productCode, data]) =>
        this.productReferenceService.loadProductReferences(
          productCode,
          data.productReferenceTypes,
          data.maximumNumberProducts
        )
      ),
      switchMap(([productCode, data]) =>
        this.retrieveProductReferences(
          productCode,
          data.productReferenceTypes ?? ''
        )
      )
    );
  readonly pdpCallSource: PDP_CALL_SOURCE = PDP_CALL_SOURCE.CAROUSEL;

  private retrieveProductReferences(
    code: string,
    referenceType: string
  ): Observable<Observable<Product | undefined>[]> {
    return this.productReferenceService
      .getProductReferences(code, referenceType)
      .pipe(
        filter((references) => Boolean(references)),
        map((references: ProductReference[]) =>
          references.map((reference) => of(reference.target))
        )
      );
  }
}
