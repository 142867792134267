export const environment = {
  production: true,
  occ: {},
  oidc: {
    subaru: {
      clientId: 'subaru-efa',
      clientSecret: 'tAh9i<J-S5^V\'O[pnHm56!',
      issuer: 'https://auth.asp.it-tecture.de',
      loginUrl: 'oauth2/authorize',
      tokenEndpoint: 'oauth2/token',
      baseUrl:
        'https://auth.asp.it-tecture.de',
      userinfoEndpoint: 'userinfo',
      revokeEndpoint: 'oauth2/revoke',
    },
  },
  siteIdToUrlSubDirectory: {
    subaru: 'subaru',
  },
};
