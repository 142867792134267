import {Injectable} from '@angular/core';
import {ViewModes} from "@spartacus/storefront";
import {Params} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class ProductListOptionsService {
  private _lastPage: number;
  private _currentViewMode: ViewModes;
  private _currentListUrl: string;
  private _currentQueryParams: Params;

  get currentListUrl(): string {
    return this._currentListUrl;
  }

  set currentListUrl(value: string) {
    if (this._currentListUrl !== value) {
      this._currentListUrl = value;
      this._lastPage = 0;
    }
  }

  get currentQueryParams(): Params {
    return this._currentQueryParams;
  }

  set currentQueryParams(value: Params) {
    if (this._currentQueryParams?.query !== value?.query) {
      this._currentQueryParams = value;
      this._lastPage = 0;
    }
  }

  get lastPage(): number {
    return this._lastPage;
  }

  set lastPage(value: number) {
    if (this.lastPage === undefined || value > this.lastPage) {
      this._lastPage = value;
    }
  }

  get currentViewMode(): ViewModes {
    return this._currentViewMode;
  }

  set currentViewMode(value: ViewModes) {
    this._currentViewMode = value;
  }

  constructor() {
  }
}
