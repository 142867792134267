import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {SpartacusModule} from './spartacus/spartacus.module';
import {CustomFunctionsModule} from './custom/functions/custom-functions.module';
import {CustomConfigurationModule} from './custom/config/custom-configuration.module';
import {ConfigDebugModule} from './custom/functions/config-debug/config-debug.module';
import {UserProfilesModule} from './custom/functions/user-profiles/user-profiles.module';
import {CategoryInformationModule} from './custom/functions/category-information/category-information.module';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {environment} from '../environments/environment';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {ConfigModule} from '@spartacus/core';
import {devDisplayConfig} from './custom/functions/config-debug/default-display.config';
import {CustomTranslationsModule} from './custom/translations/custom-translations.module';
import localeDe from '@angular/common/locales/de';
import {registerLocaleData} from '@angular/common';
import {SharedModules} from '@shared/shared.modules';
import { AppRoutingModule } from "@spartacus/storefront";

registerLocaleData(localeDe);

const devImports = [];
if (!environment.production) {
  devImports.push(StoreDevtoolsModule.instrument({connectInZone: true}));
  devImports.push(
    ConfigModule.withConfig(devDisplayConfig)
  );
}

@NgModule({
  declarations: [
    AppComponent],
  imports: [
    AppRoutingModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    HttpClientModule,
    SpartacusModule,
    ConfigDebugModule,
    CustomFunctionsModule,
    CustomConfigurationModule,
    CustomTranslationsModule,
    UserProfilesModule,
    CategoryInformationModule,
    SharedModules,
    ...devImports,
  ],

  bootstrap: [AppComponent]
})

export class AppModule {
}
