import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  CartAddEntrySuccessEvent,
  CartRemoveEntrySuccessEvent,
  CartUpdateEntrySuccessEvent,
} from '@spartacus/cart/base/root';
import { provideConfig } from '@spartacus/core';
import { NavigationEvent } from '@spartacus/storefront';
import { TmsConfig } from '@spartacus/tracking/tms/core';
import { EfaGaCollectorService } from './services/efa-ga-collector.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    provideConfig(<TmsConfig>{
      tagManager: {
        gtm: {
          debug: true,
          gtmId: 'GTM-M6WWGJHH',
          collector: EfaGaCollectorService,
          events: [
            NavigationEvent,
            CartAddEntrySuccessEvent,
            CartUpdateEntrySuccessEvent,
            CartRemoveEntrySuccessEvent,
          ],
        },
      },
    }),
  ],
})
export class GoogleAnalyticsModule {}
