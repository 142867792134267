import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig, RoutingConfig } from '@spartacus/core';
import { SubaruAspComponent } from './components/subaru-asp/subaru-asp.component';
import { SubaruAspAuthHttpHeaderInterceptor } from './interceptors/subaru-asp-auth-http-header.interceptor';

@NgModule({
  declarations: [SubaruAspComponent],
  imports: [CommonModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        SubaruAspComponent: {
          component: SubaruAspComponent,
        },
      },
    }),

    provideConfig(<RoutingConfig> {
      routing: {
        routes: {
          subaruAsp: {
            paths: ['subaru-asp'],
            protected: false,
          },
        },
      },
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: SubaruAspAuthHttpHeaderInterceptor,
      multi: true,
    },
  ],
})
export class SubaruAspModule {}
