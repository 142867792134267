import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ConfigModule, I18nModule, OccConfig } from '@spartacus/core';
import {
  CarouselModule,
  CurrentProductService,
  IconModule,
  MediaModule,
  OutletModule,
  StarRatingModule,
  SupplementHashAnchorsModule,
} from '@spartacus/storefront';

import { SharedModules } from '@shared/shared.modules';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { AddToCartModule } from '../add-to-cart/add-to-cart.module';
import { EfaProductDetailsTabComponent } from './components/efa-product-details-tab/efa-product-details-tab.component';
import { EfaProductImagesComponent } from './components/efa-product-images/efa-product-images.component';
import { EfaProductIntroComponent } from './components/efa-product-intro/efa-product-intro.component';
import { EfaProductSummaryComponent } from './components/efa-product-summary/efa-product-summary.component';
import { EfaProductUrlEncodingGuard } from './guards/efa-product-url-encoding.guard';
import { OccModule } from './occ/occ.module';
import { EfaCurrentProductService } from './services/efa-current-product.service';
import {
  productDetailsTranslationChunksConfig,
  productDetailsTranslations,
} from './translations/product-details.translations';

@NgModule({
  declarations: [
    EfaProductSummaryComponent,
    EfaProductIntroComponent,
    EfaProductImagesComponent,
    EfaProductDetailsTabComponent,
  ],
  imports: [
    CommonModule,
    OutletModule,
    I18nModule,
    StarRatingModule,
    MediaModule,
    CarouselModule,
    IconModule,
    SupplementHashAnchorsModule,
    NgxImageZoomModule,
    OccModule,
    ConfigModule.withConfig({
      backend: {
        occ: {
          endpoints: {
            product: {
              default:
                'products/${productCode}?fields=DEFAULT,averageRating,images(FULL),classifications,manufacturer,numberOfReviews,categories(FULL),baseOptions,baseProduct,variantOptions,variantType,manufacturerAID,manufacturerId',
              details:
                'products/${productCode}?fields=averageRating,stock(DEFAULT),description,availableForPickup,code,url,price(DEFAULT),numberOfReviews,manufacturer,categories(FULL),priceRange,multidimensional,tags,images(FULL),manufacturerAID,manufacturerId,productCampaign(DEFAULT)',
              variants:
                'products/${productCode}?fields=name,purchasable,baseOptions(DEFAULT),baseProduct,variantOptions(DEFAULT),variantType,manufacturer,manufacturerId,manufacturerAID',
              list: 'products/${productCode}?fields=code,name,summary,price(formattedValue,value),images(DEFAULT,galleryIndex),baseProduct,productCampaign(DEFAULT),manufacturerAID',
              list_item:
                'products/${productCode}?fields=code,name,price(formattedValue,value,currencyIso),images(DEFAULT),baseProduct',
            },
          },
        },
      },
    } as OccConfig),
    ConfigModule.withConfig({
      i18n: {
        resources: productDetailsTranslations,
        chunks: productDetailsTranslationChunksConfig,
      },
      cmsComponents: {
        ProductSummaryComponent: {
          component: EfaProductSummaryComponent,
          guards: [EfaProductUrlEncodingGuard],
        },
        ProductIntroComponent: {
          component: EfaProductIntroComponent,
        },
        ProductImagesComponent: {
          component: EfaProductImagesComponent,
        },
      },
      layoutSlots: {
        ProductDetailsPageTemplate: {
          lg: {
            pageFold: 'UpSelling',
          },
          pageFold: 'Summary',
          slots: [
            'Summary',
            'UpSelling',
            'CrossSelling',
            'PlaceholderContentSlot',
          ],
        },
      },
      routing: {
        routes: {
          product: {
            paths: ['product/:productCode'],
            paramsMapping: { productCode: 'code' },
          },
        },
      },
    }),
    SharedModules,
    AddToCartModule,
  ],
  providers: [
    {
      provide: CurrentProductService,
      useExisting: EfaCurrentProductService,
    },
  ],
  exports: [EfaProductSummaryComponent, EfaProductIntroComponent],
})
export class ProductDetailsModule {}
