import {AfterViewChecked, Component, OnDestroy, OnInit} from '@angular/core';
import {
  CmsBannerComponent,
  GlobalMessageService, ProductSearchService,
  RouterState,
  RoutingService
} from '@spartacus/core';
import {
  CmsComponentData,
  PageLayoutService,
  ProductListComponent,
  ProductListComponentService,
  ViewConfig,
  ViewModes
} from '@spartacus/storefront';
import {BehaviorSubject, debounceTime, map, Observable, skip, skipUntil, Subscription} from 'rxjs';
import {CmsProductListComponent} from '../../model/product-search.model';
import {ViewportScroller} from "@angular/common";
import {ProductListOptionsService} from "../../services/product-list-options.service";
import {distinctUntilChanged, filter, take, tap} from "rxjs/operators";
import {getProductCodeUrlEncoded} from "@shared/utils/product-tools";

@Component({
  selector: 'app-efa-product-list',
  templateUrl: './efa-product-list.component.html'
})
export class EfaProductListComponent extends ProductListComponent implements OnInit, OnDestroy {
  banner$: Observable<CmsBannerComponent>;
  private mySubscription: Subscription = new Subscription();
  myProductListComponentService: ProductListComponentService;

  private productCode: string = undefined;

  private lastPageLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    pageLayoutService: PageLayoutService,
    productListComponentService: ProductListComponentService,
    globalMessageService: GlobalMessageService,
    public scrollConfig: ViewConfig,
    private component: CmsComponentData<CmsProductListComponent>,
    private routing: RoutingService, private scroller: ViewportScroller,
    private optionsService: ProductListOptionsService,
    private searchService: ProductSearchService
  ) {
    super(pageLayoutService, productListComponentService, globalMessageService, scrollConfig);
    this.myProductListComponentService = productListComponentService;
  }

  ngOnInit(): void {
    this.searchService.clearResults();
    super.ngOnInit();

    if (this.optionsService.currentViewMode) {
      this.viewMode$.next(this.optionsService.currentViewMode);
    } else {
      this.viewMode$.next(ViewModes.Grid);
    }
    this.banner$ = this.component.data$.pipe(map((data: CmsProductListComponent) => data.bannerComponent));
    this.mySubscription.add(
      this.routing.getRouterState()
      .pipe(filter(route => route.nextState === undefined))
      .subscribe((route: RouterState) => {
        if (route.state) {
          this.productCode = this.getProductCode(route);
          this.saveCurrentUrl(route);
        }
      }));

    this.mySubscription.add(this.myProductListComponentService.model$
    .pipe(
      distinctUntilChanged((prev, curr) => {
        console.log("prev, curr", prev.pagination.currentPage, curr.pagination.currentPage);
        console.log("currentPage, last page", curr.pagination.currentPage, this.optionsService.lastPage);
        return prev.pagination.currentPage === curr.pagination.currentPage;
      }),
      skip(1)) // skip saved search result when coming back to page
    .subscribe(async m => {
      if (this.productCode !== undefined) {
        if (m.pagination.currentPage < this.optionsService.lastPage) {
          console.log("load next page", m.pagination.currentPage + 1);
          this.myProductListComponentService.getPageItems(m.pagination.currentPage + 1);
        } else {
          this.lastPageLoaded$.next(true);
        }
      }
    }));

    this.mySubscription.add(this.lastPageLoaded$.pipe(filter(loaded => loaded),debounceTime(500))
    .subscribe(m => {
        if (this.productCode !== undefined) {
          console.log("scroll to ", this.productCode);
          this.scroller.scrollToAnchor(this.productCode);
          this.productCode = undefined;
        }
      }
    ));
  }

  private saveCurrentUrl(route: RouterState) {
    let ind = route.state.url.indexOf("?", 0)
    let currentUrl = ind > -1 ? route.state.url.substring(0, ind) : route.state.url;

    ind = route.state.url.indexOf("#", 0)
    currentUrl = ind > -1 ? currentUrl.substring(0, ind) : currentUrl;

    currentUrl = currentUrl.replace("de/", "").replace("en/", "");
    this.optionsService.currentListUrl = currentUrl;
    this.optionsService.currentQueryParams = route.state.queryParams;
  }

  private getProductCode(route: RouterState) {
    let ind = route.state.url.indexOf("#", 0)
    const productCode = ind > -1 ? route.state.url.substring(ind + 1) : undefined;
    return productCode;
  }

  private getProductCodeUrlEncoded(code: string) {
    return getProductCodeUrlEncoded(code);
  }

  override setViewMode(mode) {
    this.viewMode$.next(mode);
    this.optionsService.currentViewMode = mode;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.mySubscription.unsubscribe();
  }
}
