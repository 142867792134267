import {Injectable} from '@angular/core';
import {
  ConsentService,
  CxEvent,
  ScriptLoader,
  WindowRef,
} from '@spartacus/core';
import {
  TmsCollectorConfig,
  WindowObject
} from '@spartacus/tracking/tms/core';
import {GtmCollectorService} from "@spartacus/tracking/tms/gtm";
import {first} from 'rxjs';
import {EcommerceData, GaItem} from "../model/google-analytics.model";
import {
  CartAddEntrySuccessEvent,
  CartRemoveEntrySuccessEvent,
  CartUpdateEntrySuccessEvent
} from "@spartacus/cart/base/root";

@Injectable({providedIn: 'root'})
export class EfaGaCollectorService extends GtmCollectorService {
  constructor(
    protected winRef: WindowRef,
    protected consentService: ConsentService,
    protected scriptLoader: ScriptLoader
  ) {
    super(winRef);
  }

  pushEvent<T extends CxEvent>(
    config: TmsCollectorConfig,
    windowObject: WindowObject,
    event: T | any
  ): void {
    this.consentService
    .checkConsentGivenByTemplateId('003-GOOGLE_TRACKING')
    .pipe(first())
    .subscribe((given: boolean) => {
      if (given) {
        const dataLayerProperty = config.dataLayerProperty ?? 'dataLayer';
        if (event instanceof CartAddEntrySuccessEvent ||
          event instanceof CartUpdateEntrySuccessEvent ||
          event instanceof CartRemoveEntrySuccessEvent) {
          windowObject[dataLayerProperty].push(
            {
              'event': (event as any).constructor.type,
              ...event,
              'ecommerce': this.createEcommerceData(event)
            }
          );
        } else {
          windowObject[dataLayerProperty].push(
            {
              'event': event.constructor.type,
              ...event,
            });
        }
      }
    });
  }

  createEcommerceData<T extends CxEvent>(
    event: T | any
  ): EcommerceData {
    if (event instanceof CartAddEntrySuccessEvent || event instanceof CartUpdateEntrySuccessEvent || event instanceof CartRemoveEntrySuccessEvent) {
      let gaItem: GaItem = {
        item_id: event.entry.product.code,
        item_name: event.entry.product.name,
        item_brand: event.entry.product.manufacturer,
        price: event.entry.customerBasePrice.value,
        quantity: event.entry.quantity
      };
      return {
        value: event.entry.totalPrice.value,
        currency: 'EUR',
        items: [gaItem],
      }
    }
  }
}
