import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AuthService,
  B2BUser,
  EventService,
  RoutingService,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { filter, iif, mergeMap, Observable, of, Subscription } from 'rxjs';
import { TermsConfirmationEvent } from '../../../login-register/model/login-register.model';

const HOME_ROUTE_NAME = 'home';
const TERMS_AND_CONDITIONS_ROUTE_NAME = 'termsAndConditionsConfirmation';

@Component({
  selector: 'app-subaru-asp',
  template: '',
})
export class SubaruAspComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  constructor(
    protected authService: AuthService,
    protected routingService: RoutingService,
    protected userFacade: UserAccountFacade,
    protected eventService: EventService
  ) {}

  ngOnInit(): void {
    const loggedInUserObservable$: Observable<B2BUser> = this.userFacade
      .get()
      .pipe(filter(Boolean));
    const notLoggedInUserObservable$: Observable<unknown> = of(undefined);

    this.subscription.add(
      this.authService
        .isUserLoggedIn()
        .pipe(
          mergeMap((isLoggedIn: boolean) =>
            iif(
              () => isLoggedIn,
              loggedInUserObservable$,
              notLoggedInUserObservable$
            )
          )
        )
        .subscribe((user: B2BUser) => {
          if (user == null) {
            this.authService.loginWithRedirect();
          } else if (user?.termsAccepted) {
            this.routingService.go({
              cxRoute: HOME_ROUTE_NAME,
            });
            this.eventService.dispatch(new TermsConfirmationEvent(true));
          } else {
            this.routingService.go(
              {
                cxRoute: TERMS_AND_CONDITIONS_ROUTE_NAME,
              },
              { queryParams: { forceCoreLogoutAndReload: true } }
            );
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
