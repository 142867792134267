import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GlobalMessageType } from '@spartacus/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ErrorType } from '@shared/models/ErrorTypes';
import { EfaGlobalMessageService } from '@shared/services/efa-global-message.service';
import { CartModificationList } from '../../model/cart-administration.model';
import { MultiAddToCartConnector } from '../../occ/connectors/multi-add-to-cart.connector';
import { MultiAddToCartActions } from '../actions';
import { MultiCartFacade } from '@spartacus/cart/base/root';

@Injectable()
export class MultiAddToCartEffect {
  loadMultiAddToCarts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MultiAddToCartActions.loadMultiAddToCart),
      switchMap((action) =>
        this.connector.addEntries(action.entries, action.userId, action.cartId).pipe(
          map((cartModificationList: CartModificationList) => {
            this.multiCartService.loadCart({cartId: action.cartId, userId: action.userId});
            return MultiAddToCartActions.loadMultiAddToCartSuccess({
              modifications: cartModificationList, cartId: action.cartId, userId: action.userId
            });
          }),
          catchError((error: ErrorType) => {
            this.globalMessageService.add(
              {
                key: 'cartAdministration.globalMessage.multiAddToCartFailure',
              },
              GlobalMessageType.MSG_TYPE_ERROR
            );
            return of(MultiAddToCartActions.loadMultiAddToCartFailure({ errorResponse:  error }));
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private connector: MultiAddToCartConnector,
    private multiCartService: MultiCartFacade,
    private globalMessageService: EfaGlobalMessageService,
  ) {}
}
