import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {AuthGuard, ConfigModule, I18nModule, UrlModule} from '@spartacus/core';
import {CardModule, FormErrorsModule, IconModule, ListNavigationModule, PromotionsModule, SpinnerModule} from '@spartacus/storefront';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {META_REDUCERS, StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {NgSelectModule} from '@ng-select/ng-select';
import {OccModule} from './occ/occ.module';
import {ORDER_HISTORY_FEATURE_KEY, reducers} from './store/reducers';
import {effects} from './store/effects';
import {orderHistoryTranslationChunksConfig, orderHistoryTranslations} from './translations/order-history.translations';
import {OrderOverviewFilterComponent} from './components/order-overview-filter/order-overview-filter.component';
import {EfaOrderHistoryComponent} from './components/efa-order-history/efa-order-history.component';
import {EfaOrderDetailsShippingComponent} from './components/efa-order-details-shipping/efa-order-details-shipping.component';
import {EfaOrderOverviewComponent} from './components/efa-order-overview/efa-order-overview.component';
import {EfaOrderDetailItemsComponent} from './components/efa-order-detail-items/efa-order-detail-items.component';
import {metaReducerFactoryOrderOverview} from './store/reducers/order-overview.reducer';
import {metaReducerFactoryOrderDetails} from './store/reducers/order-details.reducer';
import {EfaOrderDetailsService} from './services/efa-order-details.service';
import {SharedModules} from '@shared/shared.modules';
import {OrderDetailsModule, OrderDetailsService} from '@spartacus/order/components';
import {EfaOrderDetailTotalsComponent} from './components/efa-order-detail-totals/efa-order-detail-totals.component';
import {OrderDetailActionsComponent} from './components/order-detail-actions/order-detail-actions.component';
import {EfaOrderSortingComponent} from './components/efa-order-sorting/efa-order-sorting.component';

@NgModule({
  declarations: [
    OrderOverviewFilterComponent,
    EfaOrderHistoryComponent,
    EfaOrderDetailsShippingComponent,
    EfaOrderOverviewComponent,
    EfaOrderDetailItemsComponent,
    EfaOrderDetailTotalsComponent,
    OrderDetailActionsComponent,
    EfaOrderSortingComponent,
  ],
  imports: [
    CommonModule,
    OccModule,
    I18nModule,
    ReactiveFormsModule,
    FormsModule,
    FormErrorsModule,
    IconModule,
    UrlModule,
    ListNavigationModule,
    NgSelectModule,
    NgbModule,
    RouterModule,
    CardModule,
    PromotionsModule,
    SpinnerModule,
    SharedModules,
    OrderDetailsModule,
    ConfigModule.withConfig({
      icon: {
        symbols: {
          FILTER: 'fas fa-filter',
          SORTING: 'fas fa-sort',
        }
      }
    }),
    ConfigModule.withConfig({
      i18n: {
        resources: orderHistoryTranslations,
        chunks: orderHistoryTranslationChunksConfig
      },
      cmsComponents: {
        AccountOrderHistoryComponent: {
          component: EfaOrderHistoryComponent,
          guards: [AuthGuard],
        },
        AccountOrderDetailsShippingComponent: {
          component: EfaOrderDetailsShippingComponent,
        },
        AccountOrderDetailsItemsComponent: {
          component: EfaOrderDetailItemsComponent,
        },
        AccountOrderDetailsTotalsComponent: {
          component: EfaOrderDetailTotalsComponent,
        },
        AccountOrderDetailsActionsComponent: {
          component: OrderDetailActionsComponent,
        },
      },
      backend: {
        occ: {
          endpoints: {
            orderOverview: 'users/${userId}/orderhistory?fields=DEFAULT',
            orderDetail: 'users/${userId}/orders/${orderId}?fields=DEFAULT',
          }
        }
      },
      routing: {
        routes: {
          orderDetails: {
            paths: ['my-account/documents/order/:sapOrderNumber'],
            paramsMapping: { sapOrderNumber: 'code' },
          },
          orders: {
            paths: ['my-account/documents/orders'],
          }
        }
      }
    }),
    StoreModule.forFeature(ORDER_HISTORY_FEATURE_KEY, reducers),
    EffectsModule.forFeature(effects),
  ],
  providers: [
    {
      provide: META_REDUCERS,
      deps: [],
      useFactory: metaReducerFactoryOrderOverview,
      multi: true,
    },
    {
      provide: META_REDUCERS,
      deps: [],
      useFactory: metaReducerFactoryOrderDetails,
      multi: true,
    },
    {
      provide: OrderDetailsService,
      useClass: EfaOrderDetailsService,
    },
  ]
})
export class OrderHistoryModule {
}
